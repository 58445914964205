import React, { useRef, useEffect } from 'react';

import {
  MaqsueBannerHome,
  MaqsueBannerPageMobile,
  MaqsueBannerPageTablet,
} from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';
import ScrollIcon from '../../Molecules/ScrollIcon/ScrollIcon';

import './HomeBanner.scss';

let classNames = require('classnames');

const HomeBanner = ({ homeBannerNode, imagesArray, videosArray, children }) => {
  const { processUrl, getImage, getVideo } = useMedias();
  const vidRef = useRef(null);

  const is_video =
    homeBannerNode.field_type_de_media === 'video' ? true : false;
  let desktop_image = getImage(
    imagesArray,
    homeBannerNode?.relationships?.field_image?.drupal_internal__mid
  );
  let mobile_image = getImage(
    imagesArray,
    homeBannerNode?.relationships?.field_mobile_image?.drupal_internal__mid
  );
  let videoLink = getVideo(
    videosArray,
    homeBannerNode?.relationships?.field_video?.drupal_internal__mid
  )?.uri?.url;

  useEffect(() => {
    if (is_video && vidRef) {
      vidRef.current.play();
      vidRef.current.addEventListener(
        'loadeddata',
        () => {
          vidRef.current.play();
        },
        false
      );
    }
  }, []);

  return (
    <>
      <div className="visuel">
        {is_video ? (
          <>
            <div className="only_desktop">
              <video ref={vidRef} loop muted controls={false} autoPlay>
                <source src={processUrl(videoLink)} type="video/mp4" />
              </video>
            </div>
            <div
              className={classNames(
                'mobile_visuel_container',
                'only_mobile_tablet'
              )}
            >
              <img
                src={processUrl(mobile_image?.image_style_uri?.gatsby_medium)}
                alt={homeBannerNode?.relationships?.mobile_image?.field_media_image?.alt || ''}
                style={{
                  width: '100%',
                  height: '100%',
                  opacity: 'auto',
                  objectFit: 'cover',
                }}
              />
            </div>
          </>
        ) : (
          <img
            src={processUrl(desktop_image?.image_style_uri?.gatsby_wide)}
            alt={homeBannerNode?.relationships?.field_image?.field_media_image?.alt || ''}
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              objectFit: 'cover',
            }}
          />
        )}
        <MaqsueBannerHome
          className={classNames('shape_yellow_banner_bottom', 'only_desktop')}
        />
        <MaqsueBannerPageMobile
          className={classNames('shape_yellow_banner_bottom', 'only_mobile')}
        />
        <MaqsueBannerPageTablet
          className={classNames('shape_yellow_banner_bottom', 'only_tablet')}
        />
      </div>
      <div className="visuel_overlay">
        {children}
        <div className="scroll_icon_container">
          <ScrollIcon />
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
