import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { HorizontalYellowLineShape } from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';
import { Button } from '../../Molecules/Button/Button';
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import TitleSection from '../../Molecules/TitleSection/TitleSection';

import './HomeOurActivities.scss';
import { getSectionBG, getTextColor, tradDroiteGauche } from '../../Utils/Utils';

let classNames = require('classnames');

const HomeOurActivities = ({ activitiesAllDetails, imagesArray }) => {
  const { processUrl, getImage } = useMedias();

  const [active, setActive] = useState(0);
  const [loop, setLoop] = useState();
  const AllActivities = activitiesAllDetails;
  const menu_our_activities = [];
  const timerDelay = 3000;
  const j = useRef(0);

  useEffect(() => {
    setLoop(
      setInterval(function () {
        setActive(j.current);
        if (j.current == menu_our_activities.length - 1) {
          j.current = -1;
        }
        j.current++;
      }, timerDelay)
    );

    return () => clearInterval(loop);
  }, []);

  AllActivities.relationships?.field_nos_activites?.forEach((item) => {
    menu_our_activities.push({
      title: item.title,
      class: 'transport',
      link: '/' + item.path?.langcode + item.path?.alias,
      visuel: getImage(
        imagesArray,
        item?.relationships?.field_image?.drupal_internal__mid
      ),
      alt: item?.relationships?.field_image?.field_media_image?.alt || ''
    });
  });

  const addActiveClass = (e, i) => {
    e.preventDefault();
    j.current = i;
    setActive(i);
    clearInterval(loop);
  };
  return (
    <section
      className={classNames(
        'home_our_activities',
        'section_content',
        AllActivities.field_backgroud_color != 'ice' &&
          AllActivities.field_backgroud_color != 'navy'
          ? 'index_top'
          : null
      )}
    >
      <ShapeBackground
        top={tradDroiteGauche(AllActivities?.field_position_bordure_haut)}
        color={getSectionBG(AllActivities?.field_backgroud_color)}
        bottomInfinite={AllActivities.field_infinite_border}
        bottom={tradDroiteGauche(AllActivities?.field_position_bordure_bas)}
      >
        <div className="wrapper_page_xs">
          <TitleSection
            title={AllActivities.field_title.processed}
            subtitle={AllActivities.body.processed}
            type="arrow"
            h2color={getTextColor(getSectionBG(AllActivities?.field_backgroud_color))}
            h4color={getTextColor(getSectionBG(AllActivities?.field_backgroud_color))}
          />
        </div>
        <div className="wrapper_our_activities">
          <div className="menu" role="tablist" aria-label={AllActivities.field_title.processed}>
            {menu_our_activities.map((menu_our_activitie, i) => (
              <div className="menu-item" key={i}>
                <button
                  href="#"
                  aria-selected={active === i}
                  aria-controls={`tab-panel-${i}`}
                  className={classNames({ active: active === i })}
                  onClick={(e) => addActiveClass(e, i)}
                  role='tab'
                >
                  <span>{menu_our_activitie.title}</span>
                  {active === i && <HorizontalYellowLineShape />}
                </button>
              </div>
            ))}
          </div>
          <div className="visuel_text_container">
            {menu_our_activities.map((menu_our_activitie_, k) => (
              <div
                className={classNames('visuel_text', { show: active === k })}
                role='tabpanel'
                id={`tab-panel-${k}`}
                key={k}
              >
                <div className="visuel">
                  <img
                    src={processUrl(menu_our_activitie_.visuel?.image_style_uri?.gatsby_activites_carousel)}
                    alt={menu_our_activitie_.alt}
                    aria-hidden="true"
                    style={{
                      width: '100%',
                      height: '570px',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div className="text">
                  <p>{menu_our_activitie_.title} </p>
                  <Button
                    label={AllActivities.field_link_title}
                    size="medium"
                    link={menu_our_activitie_.link}
                    target="_self"
                    aria-label={`${AllActivities.field_link_title} ${menu_our_activitie_.title}`}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </ShapeBackground>
    </section>
  );
};

HomeOurActivities.propTypes = {
  activitiesAllDetails: PropTypes.object.isRequired,
  imagesArray: PropTypes.array.isRequired,
};

export default HomeOurActivities;
